import { Avatar, AvatarProps } from 'antd'
import { lightOrDark, stringToColour } from 'helpers/common'
import React, { useCallback, useMemo } from 'react'

function getTwoChar(fullName: string) {
    const splitted = fullName.split(' ')
    //splitted.length
    return `${splitted[0]?.substring(0, 1)}${splitted[1]?.substring(0, 1)}`.toLocaleUpperCase()
}
export interface ColorAvatarProps extends AvatarProps {
    src?: string;
    fullName: string;
    style?: React.CSSProperties;
    className?: string;
}
export default function ColorAvatar({ src, fullName = "User", ...props }: ColorAvatarProps) {
    const twoChar = useMemo(() => getTwoChar(fullName), [fullName])
    const bg = useMemo(() => stringToColour(fullName), [fullName])
    const color = useMemo(() => lightOrDark(bg), [bg])
    return (
        <Avatar src={src} style={{ backgroundColor: bg, color: color === 'light' ? 'black' : 'white' }} {...props}>
            {twoChar}
        </Avatar>
    )
}
