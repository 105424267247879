import { lazy } from "react";

const PagesRoutes = [
  // COMPONENTS
  /* {
     path: "/components/components-page",
     component: lazy(() => import("components/components-page")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/avatar",
     component: lazy(() => import("components/data-display/avatar")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/badge",
     component: lazy(() => import("components/data-display/badge")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/calendar",
     component: lazy(() =>
       import("components/data-display/calendar")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/card",
     component: lazy(() => import("components/data-display/card")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/collapse",
     component: lazy(() =>
       import("components/data-display/collapse")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/comment",
     component: lazy(() => import("components/data-display/comment")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/empty",
     component: lazy(() => import("components/data-display/empty")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/list",
     component: lazy(() => import("components/data-display/list")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/popover",
     component: lazy(() => import("components/data-display/popover")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/table",
     component: lazy(() => import("components/data-display/table")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/tabs",
     component: lazy(() => import("components/data-display/tabs")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/tag",
     component: lazy(() => import("components/data-display/tag")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/timeline",
     component: lazy(() =>
       import("components/data-display/timeline")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-display/tooltip",
     component: lazy(() =>
       import("components/data-display/tooltip")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/checkbox",
     component: lazy(() => import("components/data-entry/checkbox")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/datepicker",
     component: lazy(() =>
       import("components/data-entry/datepicker")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/form",
     component: lazy(() => import("components/data-entry/form")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/inputs",
     component: lazy(() => import("components/data-entry/inputs")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/input-number",
     component: lazy(() =>
       import("components/data-entry/input-number")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/radio",
     component: lazy(() =>
       import("components/data-entry/radio")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/rate",
     component: lazy(() => import("components/data-entry/rate")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/select",
     component: lazy(() => import("components/data-entry/select")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/slider",
     component: lazy(() => import("components/data-entry/slider")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/switch",
     component: lazy(() => import("components/data-entry/switch")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/data-entry/upload",
     component: lazy(() => import("components/data-entry/upload")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/alert",
     component: lazy(() => import("components/feedback/alert")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/drawer",
     component: lazy(() => import("components/feedback/drawer")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/message",
     component: lazy(() => import("components/feedback/message")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/modal",
     component: lazy(() => import("components/feedback/modal")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/notification",
     component: lazy(() =>
       import("components/feedback/notification")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/popconfirm",
     component: lazy(() => import("components/feedback/popconfirm")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/progress",
     component: lazy(() => import("components/feedback/progress")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/result",
     component: lazy(() => import("components/feedback/result")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/feedback/skeleton",
     component: lazy(() => import("components/feedback/skeleton")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/general/buttons",
     component: lazy(() => import("components/general/buttons")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/general/icons",
     component: lazy(() => import("components/general/icons")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/general/style-guide",
     component: lazy(() => import("components/general/style-guide")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/navigation/breadcrumb",
     component: lazy(() =>
       import("components/navigation/breadcrumb")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/navigation/dropdown",
     component: lazy(() => import("components/navigation/dropdown")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/navigation/menu",
     component: lazy(() => import("components/navigation/menu")),
     layout: "VerticalLayout",
   },
   {
     path: "/components/navigation/pagination",
     component: lazy(() =>
       import("components/navigation/pagination")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/components/navigation/steps",
     component: lazy(() => import("components/navigation/steps")),
     layout: "VerticalLayout",
   },
 
   // MAIN
   {
     path: "/main/dashboard/analytics",
     component: lazy(() => import("main/dashboard/analytics")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/dashboard/nft",
     component: lazy(() => import("main/dashboard/nft")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/dashboard/ecommerce",
     component: lazy(() => import("main/dashboard/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/cards/advance",
     component: lazy(() => import("main/widgets/cards/advance")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/cards/analytics",
     component: lazy(() => import("main/widgets/cards/analytics")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/cards/statistics",
     component: lazy(() => import("main/widgets/cards/statistics")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/selectbox",
     component: lazy(() => import("main/widgets/selectbox")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/illustration-set",
     component: lazy(() => import("main/widgets/illustration-set")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/crypto-icons",
     component: lazy(() => import("main/widgets/crypto-icons")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/user-icons",
     component: lazy(() => import("main/widgets/user-icons")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/flags",
     component: lazy(() => import("main/widgets/flags")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/widgets/charts",
     component: lazy(() => import("main/widgets/charts")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/layouts/divider",
     component: lazy(() => import("main/layouts/divider")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/layouts/grid-system",
     component: lazy(() => import("main/layouts/grid-system")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/layouts/page-layouts/boxed-layout",
     component: lazy(() => import("main/layouts/page-layouts/boxed")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/layouts/page-layouts/vertical-layout",
     component: lazy(() => import("main/layouts/page-layouts/vertical")),
     layout: "VerticalLayout",
   },
   {
     path: "/main/layouts/page-layouts/horizontal-layout",
     component: lazy(() => import("main/layouts/page-layouts/horizontal")),
     layout: "HorizontalLayout",
   },
   {
     path: "/main/layouts/page-layouts/full-layout",
     component: lazy(() => import("main/layouts/page-layouts/full")),
     layout: "FullLayout",
   },
 
   // APPS
   {
     path: "/apps/calendar",
     component: lazy(() => import("apps/calendar")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/contact",
     component: lazy(() => import("apps/contact")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/contact/contact-detail",
     component: lazy(() => import("apps/contact")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/shop",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/inventory",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/inventory-new-product",
     component: lazy(() => import("apps/ecommerce/inventory/new-product")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/wishlist",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/product-detail",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/checkout",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/address-information",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
   {
     path: "/apps/ecommerce/payment",
     component: lazy(() => import("apps/ecommerce")),
     layout: "VerticalLayout",
   },
 
   // PAGES
   {
     path: "/pages/landing",
     component: lazy(() => import("pages/landing")),
     layout: "FullLayout",
   },
   {
     path: "/auth/login",
     component: lazy(() => import("auth/login")),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication/recover-password",
     component: lazy(() =>
       import("pages/authentication/recover-password")
     ),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication/register",
     component: lazy(() => import("pages/authentication/register")),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication/reset-password",
     component: lazy(() =>
       import("pages/authentication/reset-password")
     ),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication-modern/login",
     component: lazy(() => import("pages/authentication-modern/login")),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication-modern/recover-password",
     component: lazy(() =>
       import("pages/authentication-modern/recover-password")
     ),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication-modern/register",
     component: lazy(() => import("pages/authentication-modern/register")),
     layout: "FullLayout",
   },
   {
     path: "/pages/authentication-modern/reset-password",
     component: lazy(() =>
       import("pages/authentication-modern/reset-password")
     ),
     layout: "FullLayout",
   },
   {
     path: "/pages/blank-page",
     component: lazy(() => import("pages/blank")),
     layout: "VerticalLayout",
   },
   {
     path: "/pages/error-403",
     component: lazy(() => import("pages/errors/403")),
     layout: "FullLayout",
   },
   {
     path: "/pages/error-404",
     component: lazy(() => import("pages/errors/404")),
     layout: "FullLayout",
   },
   {
     path: "/pages/error-500",
     component: lazy(() => import("pages/errors/500")),
     layout: "FullLayout",
   },
   {
     path: "/pages/error-502",
     component: lazy(() => import("pages/errors/502")),
     layout: "FullLayout",
   },
   {
     path: "/pages/error-503",
     component: lazy(() => import("pages/errors/503")),
     layout: "FullLayout",
   },
   {
     path: "/pages/coming-soon",
     component: lazy(() => import("pages/errors/coming-soon")),
     layout: "FullLayout",
   },
   {
     path: "/pages/maintenance",
     component: lazy(() => import("pages/errors/maintenance")),
     layout: "FullLayout",
   },
   {
     path: "/pages/faq",
     component: lazy(() => import("pages/faq")),
     layout: "VerticalLayout",
   },
   {
     path: "/pages/invoice",
     component: lazy(() => import("pages/invoice")),
     layout: "VerticalLayout",
   },
   {
     path: "/pages/knowledge-base/knowledge-base-1",
     component: lazy(() =>
       import("pages/knowledge-base/knowledge-base-1")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/pages/knowledge-base/knowledge-base-2",
     component: lazy(() =>
       import("pages/knowledge-base/knowledge-base-2")
     ),
     layout: "VerticalLayout",
   },
   {
     path: "/pages/welcome",
     component: lazy(() => import("pages/lock-screen/welcome")),
     layout: "FullLayout",
   },
   {
     path: "/pages/password-is-changed",
     component: lazy(() => import("pages/lock-screen/password-is-changed")),
     layout: "FullLayout",
   },
   {
     path: "/pages/deactivated",
     component: lazy(() => import("pages/lock-screen/deactivated")),
     layout: "FullLayout",
   },
   {
     path: "/pages/lock",
     component: lazy(() => import("pages/lock-screen/lock")),
     layout: "FullLayout",
   },
   {
     path: "/pages/pricing",
     component: lazy(() => import("pages/pricing")),
     layout: "VerticalLayout",
   },
  */
  {
    path: "/profile/personel-information",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/notifications",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/activity",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/security",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/password-change",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/connect-with-social",
    component: lazy(() => import("pages/profile")),
    layout: "VerticalLayout",
  }
];

export default PagesRoutes;