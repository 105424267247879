import { Pointer, Award, House2, Category2, Buildings2, ExportCurve, TruckFast, Calendar, ImportCurve } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const transferProcess = [
    {
        header: <IntlMessages id="transfer-process" />,
    },
    {
        id: "vehicles",
        title: <IntlMessages id="vehicles" />,
        icon: <TruckFast size={18} />,
        navLink: "/transfer-process/vehicles",
    },
    /*{
        id: "vehicle-import",
        title: <IntlMessages id="vehicle-import" />,
        icon: <ImportCurve size={18} />,
        navLink: "/transfer-process/import",
    },*/
    {
        id: "transport",
        title: <IntlMessages id="transport" />,
        icon: <Calendar size={18} />,
        navLink: "/transfer-process/transport",
    },
   /* {
        id: "vehicle-export",
        title: <IntlMessages id="vehicle-export" />,
        icon: <ExportCurve size={18} />,
        navLink: "/transfer-process/export",
    },*/
];

export default transferProcess