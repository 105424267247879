export const FETCH_TRANSPORTS_SUCCESS = "FETCH_TRANSPORTS_SUCCESS";
export const FETCH_TRANSPORTS_ERROR = "FETCH_TRANSPORTS_ERROR";
export const CREATE_TRANSPORT_SUCCESS = "CREATE_TRANSPORT_SUCCESS";
export const CREATE_TRANSPORT_ERROR = "CREATE_TRANSPORT_ERROR";
export const UPDATE_TRANSPORT_SUCCESS = "UPDATE_TRANSPORT_SUCCESS";
export const START_TRANSPORT_SUCCESS = "START_TRANSPORT_SUCCESS";
export const START_TRANSPORT_ERROR = "START_TRANSPORT_ERROR";
export const END_TRANSPORT_SUCCESS = "END_TRANSPORT_SUCCESS";
export const END_TRANSPORT_ERROR = "END_TRANSPORT_ERROR";

export const UPDATE_TRANSPORT_ERROR = "UPDATE_TRANSPORT_ERROR";
export const DELETE_TRANSPORT_SUCCESS = "DELETE_TRANSPORT_SUCCESS";
export const DELETE_TRANSPORT_ERROR = "DELETE_TRANSPORT_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_SELECTED_TRANSPORT = "SET_SELECTED_TRANSPORT";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";

