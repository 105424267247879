const apiUrl = {
    signIn: "/wapi/v1/account/auth:signin",
    signUp: "/wapi/v1/account/auth:signup",

    /* Araçlar */
    searchVehicles: "/wapi/v1/vehicles/search",
    createVehicle: "/wapi/v1/vehicles",
    updateVehicle: "/wapi/v1/vehicles",
    openVehicle: "/wapi/v1/vehicles/open",
    deloadDelivery: "/wapi/v1/vehicles/deload",
    deleteVehicle: "/wapi/v1/vehicles",
    getVehicles: "/wapi/v1/vehicles/getVehicle",
    addDelivery: "/wapi/v1/vehicles/delivery",

    /* Araçlar */

    /* Paketler */
    searchPackages: "/wapi/v1/package/search",
    createPackage: "/wapi/v1/package",
    updatePackage: "/wapi/v1/package",
    deletePackage: "/wapi/v1/package",
    getPackages: "/wapi/v1/package/getPackage",
    /* Paketler */

    /*Ürünler*/
    searchProductTypes: "/wapi/v1/productType/search",
    createProductType: "/wapi/v1/productType",
    updateProductType: "/wapi/v1/productType",
    deleteProductType: "/wapi/v1/productType",
    getProductTypes: "/wapi/v1/productType/getProductType",
    /*Ürünler*/

    /*Kullanıcılar*/
    searchUsers: "/wapi/v1/user/search",
    createUser: "/wapi/v1/user/createApi",
    updateUser: "/wapi/v1/user",
    deleteUser: "/wapi/v1/user",
    getUsers: "/wapi/v1/user/getUsers",
    /*Kullanıcılar*/

    /*Müşteriler*/
    searchCustomers: "/wapi/v1/customer/search",
    createCustomer: "/wapi/v1/customer",
    updateCustomer: "/wapi/v1/customer",
    deleteCustomer: "/wapi/v1/customer",
    getCustomer: "/wapi/v1/customer/getCustomer",
    /*Müşteriler*/

    /*Gönderiler*/
    searchDeliveries: "/wapi/v1/delivery/search",
    changeDeliveryStatus: "/wapi/v1/delivery/changeDeliveryStatus",
    changePackageStatus: "/wapi/v1/delivery/changePackageStatus",
    updateDelivery: "/wapi/v1/delivery",
    createDelivery: "/wapi/v1/delivery",
    getDelivery: "/wapi/v1/delivery/getDelivery",
    deleteDelivery: "/wapi/v1/delivery",
    createLabel: "/wapi/v1/delivery/createLabel",
    /*Gönderiler*/

    /*Şubeler*/
    searchBranches: "/wapi/v1/branch/search",
    updateBranch: "/wapi/v1/branch",
    createBranch: "/wapi/v1/branch",
    getBranch: "/wapi/v1/branch/getBranch",
    deleteBranch: "/wapi/v1/branch",
    /*Şubeler*/

    /*Depo*/
    searchWarehouses: "/wapi/v1/warehouse/search",
    updateWarehouse: "/wapi/v1/warehouse",
    createWarehouse: "/wapi/v1/warehouse",
    getWarehouse: "/wapi/v1/warehouse/getWarehouse",
    deleteWarehouse: "/wapi/v1/warehouse",
    /*Depo*/

    /*Seferler*/
    searchTransports: "/wapi/v1/transport/search",
    updateTransport: "/wapi/v1/transport",
    startTransport: "/wapi/v1/transport/start",
    addStoppingPoint: "/wapi/v1/transport/stoppingPoints",
    exportPackingList: "/wapi/v1/transport/export/packingList",
    endTransport: "/wapi/v1/transport/end",
    createTransport: "/wapi/v1/transport",
    getTransport: "/wapi/v1/transport/getTransport",
    deleteTransport: "/wapi/v1/transport",
    /*Seferler*/

    profilePhoto: "/wapi/v1/account/profile/photo",
    user: "/wapi/v1/account",
    passwordChange: "/wapi/v1/account/password",
    countries: "/wapi/v1/country/search",
    getEUCountries: "/wapi/v1/country/getEUCountries",


};

export default apiUrl;
