export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
export function openInNewTab(url) {
    const pdfWindow = window.open('about:blank');
    pdfWindow.document.location.href = url;
};
export function downloadFile(url, fileName) {
    let a = document.createElement('a');
    a.download = fileName
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export const getExtname = function (path, plain = false) {
    if (!path || path.indexOf('.') === -1) { return '' }
    path = `${plain ? '' : '.'}${path.split('.').pop().toLowerCase()}`
    return /.*(\..*)/g.exec(path)[1] || ''
}

export function capitalizeFirstLetters(str) {
    if (str) {
        return str.trim().toLocaleLowerCase(locales).split(' ').map(word => {
            return String(word[0]).toLocaleUpperCase(locales) + word.substr(1);
        }).join(' ');
    }
}

export function isNoneValue(value) {
    if (value == null || value == undefined || value == "" || value == "null" || value == []) {
        return true
    }
    return false
}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
export function replaceAll(str, term, replacement) {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

export function convertToTurkishCurrency(number, preFix = "₺") {
    if (!this.isNoneValue(number)) {
        var strNumber = this.replaceAll(String(parseFloat(number).toFixed(2)), '.', ',')
        return preFix + strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
    return ""
}

export function addressStringBuilder(address) {
    return `${address?.addressLine} - ${address?.zipCode} ${address?.city}/${address?.country}`
}

export function parseJSON(json) {
    try {
        return JSON.parse(json)

    } catch {
        return json
    }
}

export function removeFalsy(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
        if (obj[prop] && obj[prop] != []) {
            newObj[prop] = obj[prop];
        }
    });

    return newObj;
}
export const stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}

export function lightOrDark(color, reverse = false) {
    let r, g, b, hsp = ""
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'
        )
        );

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

        return reverse ? 'dark' : 'light';
    }
    else {

        return reverse ? 'light' : 'dark';
    }
}