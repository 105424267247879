// Routes Imports
import Pages from "./Pages";
import Login from "./Login"
import Shipping from "./Shipping"
import SystemConfig from "./SystemConfig"
import TransferProcess from "./TransferProcess"

// Merge Routes
const Routes = [
    ...Login,
    ...Pages,
    ...Shipping,
    ...SystemConfig,
    ...TransferProcess,
];

export { Routes };