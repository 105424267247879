export const FETCH_PRODUCT_TYPES_SUCCESS = "FETCH_PRODUCT_TYPES_SUCCESS";
export const FETCH_PRODUCT_TYPES_ERROR = "FETCH_PRODUCT_TYPES_ERROR";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_TYPES_SUCCESS";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_TYPES_ERROR";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_TYPESE_SUCCESS";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_TYPES_ERROR";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_TYPES_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_TYPES_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT_TYPES";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";