export const FETCH_WAREHOUSES_SUCCESS = "FETCH_WAREHOUSES_SUCCESS";
export const FETCH_WAREHOUSES_ERROR = "FETCH_WAREHOUSES_ERROR";
export const GET_WAREHOUSES_SUCCESS = "GET_WAREHOUSES_SUCCESS";
export const GET_WAREHOUSES_ERROR = "GET_WAREHOUSES_ERROR";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_ERROR = "CREATE_WAREHOUSE_ERROR";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_ERROR = "UPDATE_WAREHOUSE_ERROR";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_ERROR = "DELETE_WAREHOUSE_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_SELECTED_WAREHOUSE = "SET_SELECTED_WAREHOUSE";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";

