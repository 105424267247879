export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_ERROR = "FETCH_CUSTOMERS_ERROR";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_ERROR = "CREATE_CUSTOMER_ERROR";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";