import { Award, AddCircle, BoxAdd, Box } from 'iconsax-react';

import IntlMessages from "layout/components/lang/IntlMessages";

const shipmentProcess = [
    {
        header: <IntlMessages id="shipment-process" />,
    },
    {
        id: "new",
        title: <IntlMessages id="create-new-shipment" />,
        icon: <AddCircle size={18} />,
        navLink: "/shipping/new",
    },
    {
        id: "all",
        title: <IntlMessages id="deliveries-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/deliveries",
    },
    {
        id: "received",
        title: <IntlMessages id="received-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/received",
    },
    {
        id: "in-warehouse",
        title: <IntlMessages id="in-warehouse-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/in-warehouse",
    },
    {
        id: "in-vehicle",
        title: <IntlMessages id="in-vehicle-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/in-vehicle",
    },
    {
        id: "shipped",
        title: <IntlMessages id="shipped-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/in-transfer",
    },
    {
        id: "delivered",
        title: <IntlMessages id="delivered-title" />,
        icon: <Box size={18} />,
        navLink: "/shipping/delivered",
    }
];

export default shipmentProcess