import _ from 'lodash'

export function findAndUpdate(array, record, matchField = '_id', upsert = false) {

    let foundIndex = -1
    if (Array.isArray(matchField)) {
        for (const key of matchField) {
            foundIndex = array.findIndex(val => _.get(val, key) === _.get(record, key))
            if (foundIndex !== -1)
                break;
        }
    }
    else {
        foundIndex = array.findIndex(val => _.get(val, matchField) === _.get(record, matchField))
    }

    if (foundIndex !== -1)
        array[foundIndex] = record
    else if (upsert)
        array.push(record)

    return array
}
export function findAndDelete(array, record, matchField = '_id') {
    return array.filter(val => _.get(val, matchField) !== _.get(record, matchField))
}
export function removeFalsyProp(obj) {
    return _.omitBy(obj, _.overSome([_.isNil, _.isNaN]))
}
export function arrayToNestedObject(array, options = { removeFalsy: false, pathKey: 'key', valueKey: 'value' }) {
    let obj = {}
    console.log(options);
    array.map(x => _.set(obj, x[options.pathKey], x[options.valueKey]))
    return options.removeFalsy ? removeFalsyProp(obj) : obj
}

export const searchInArray = (searchQuery, array, objectKey = null) => {

    return array.filter(d => {
        const searchItem = String(objectKey ? _.get(d, objectKey) : d).toLocaleLowerCase('tr-TR') //Incase If It's Array Of Objects.
        const searchWord = searchQuery.toLocaleLowerCase('tr-TR')
        return searchItem.includes(searchWord)
    })

}