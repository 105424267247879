import { lazy } from "react";

const TransferProcessRoutes = [
    {
        path: "/transfer-process/vehicles",
        component: lazy(() => import("pages/transfer-process/vehicles")),
        layout: "VerticalLayout",
    },
    {
        path: "/transfer-process/import",
        component: lazy(() => import("pages/transfer-process/import")),
        layout: "VerticalLayout",
    },
    {
        path: "/transfer-process/export",
        component: lazy(() => import("pages/transfer-process/export")),
        layout: "VerticalLayout",
    },
    {
        path: "/transfer-process/transport",
        component: lazy(() => import("pages/transfer-process/transports")),
        layout: "VerticalLayout",
    },
];

export default TransferProcessRoutes;