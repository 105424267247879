import modalTypes from "enums/modal";
import * as actionTypes from "./branchTypes";
import { findAndDelete, findAndUpdate } from "helpers/array";

const INITIAL_STATE = {
  branches: [],
  totalBranchCount: 0,
  newBranch: {
    packages: [],
    totalPackageCount: 0,
    totalPrice: 0,
    totalVolume: 0
  },
  selectedBranch: {},
  createEditModalType: modalTypes.CREATE,
  loading: false,
  errorMessage: "",
  createEditModalOpen: false,
};

// action =  { type, payload }
const customiseReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: true };
    case actionTypes.FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: [...payload.data],
        totalBranchCount: payload.totalCount,
        loading: false,
      };
    case actionTypes.FETCH_BRANCHES_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case actionTypes.CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        branches: [payload, ...state.branches],
        totalBranchCount: ++state.totalBranchCount,
        createEditModalOpen: false,
        loading: false,
      };
    case actionTypes.CREATE_BRANCH_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case actionTypes.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        branches: [...findAndUpdate(state.branches, payload)],
        createEditModalOpen: false,
        loading: false,
      };
    case actionTypes.UPDATE_BRANCH_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case actionTypes.DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        branches: [...findAndDelete(state.branches, payload)],
        createEditModalOpen: false,
        loading: false,
        selectedBranch: {},
      };
    case actionTypes.DELETE_BRANCH_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case actionTypes.TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, loading: false, createEditModalOpen: payload };
    case actionTypes.SET_NEW_BRANCH:
      return { ...state, loading: false, newBranch: payload };
    case actionTypes.SET_SELECTED_BRANCH:
      return { ...state, loading: false, selectedBranch: payload };
    case actionTypes.SET_MODAL_TYPE:
      return { ...state, loading: false, createEditModalType: payload };
    default:
      return state;
  }
};

export default customiseReducer;
