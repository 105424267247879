export const FETCH_DELIVERIES_SUCCESS = "FETCH_DELIVERIES_SUCCESS";
export const FETCH_DELIVERIES_ERROR = "FETCH_DELIVERIES_ERROR";
export const CREATE_DELIVERY_SUCCESS = "CREATE_DELIVERY_SUCCESS";
export const CREATE_DELIVERY_ERROR = "CREATE_DELIVERY_ERROR";
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS";
export const UPDATE_DELIVERY_ERROR = "UPDATE_DELIVERY_ERROR";
export const DELETE_DELIVERY_SUCCESS = "DELETE_DELIVERY_SUCCESS";
export const DELETE_DELIVERY_ERROR = "DELETE_DELIVERY_ERROR";
export const CREATE_DELIVERY_LABEL_ERROR = "CREATE_DELIVERY_LABEL_ERROR";
export const CREATE_DELIVERY_LABEL_SUCCESS = "CREATE_DELIVERY_LABEL_SUCCESS";
export const CHANGE_PACKAGE_STATUS_SUCCESS = "CHANGE_PACKAGE_STATUS_SUCCESS";
export const CHANGE_PACKAGE_STATUS_ERROR = "CHANGE_PACKAGE_STATUS_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_NEW_DELIVERY = "SET_NEW_DELIVERY";
export const RESET_NEW_DELIVERY = "RESET_NEW_DELIVERY";

export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_SELECTED_DELIVERY = "SET_SELECTED_DELIVERY";
export const SET_SELECTED_PACKAGE = "SET_SELECTED_PACKAGE";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";
export const TOGGLE_EDIT_DELIVERY_LIST_MODAL = "TOGGLE_EDIT_DELIVERY_LIST_MODAL";
export const TOGGLE_PACKAGE_CREATE_EDIT_MODAL = "TOGGLE_PACKAGE_CREATE_EDIT_MODAL";

