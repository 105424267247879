import { lazy } from "react";

const ShippingRoutes = [
    {
        path: "/shipping/new",
        component: lazy(() => import("pages/shipping/new")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/deliveries",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/received",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/in-warehouse",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/in-vehicle",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/in-transfer",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
    {
        path: "/shipping/delivered",
        component: lazy(() => import("pages/shipping/deliveries")),
        layout: "VerticalLayout",
    },
];

export default ShippingRoutes;