import { Pointer, Award, House2, Category2, Buildings2, Profile2User, Box } from 'iconsax-react';

import IntlMessages from "layout/components/lang/IntlMessages";

const systemConfig = [
    {
        header: <IntlMessages id="system-config" />,
    },
    {
        id: "packageTypes",
        title: <IntlMessages id="package-types" />,
        icon: <Box size={18} />,
        navLink: "/system-config/package-types",
    },
    {
        id: "productTypes",
        title: <IntlMessages id="product-types" />,
        icon: <Category2 size={18} />,
        navLink: "/system-config/product-types",
    },
    {
        id: "branches",
        title: <IntlMessages id="branches" />,
        icon: <Buildings2 size={18} />,
        navLink: "/system-config/branches",
    },
    {
        id: "warehouses",
        title: <IntlMessages id="warehouses" />,
        icon: <House2 size={18} />,
        navLink: "/system-config/warehouses",
    },
    {
        id: "accounts",
        title: <IntlMessages id="accounts" />,
        icon: <Profile2User size={18} />,
        children: [
            {
                id: "customers",
                title: <IntlMessages id="customers" />,
                navLink: "/system-config/accounts/customers",
            },
            {
                id: "users",
                title: <IntlMessages id="users" />,
                navLink: "/system-config/accounts/users",
            }
        ]
    },
  /*  {
        id: "service-points",
        title: <IntlMessages id="service-points" />,
        icon: <Pointer size={18} />,
        children: [
            {
                id: "countries",
                title: <IntlMessages id="countries" />,
                navLink: "/components/general/icons",
            },
            {
                id: "cities",
                title: <IntlMessages id="cities" />,
                navLink: "/main/widgets/illustration-set",
            }
        ]
    },*/
];

export default systemConfig