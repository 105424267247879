import { combineReducers } from "redux";

import calendarReducer from "./calendar/calendarReducer";
import contactReducer from "./contact/contactReducer";
import customiseReducer from "./customise/customiseReducer";
import ecommerceReducer from "./ecommerce/ecommerceReducer";
import vehicleReducer from "./vehicle/vehicleReducer";
import packageTypesReducer from "./packageTypes/packageTypesReducer";
import authReducer from "./auth/authReducer";
import productTypesReducer from "./productTypes/productTypesReducer";
import userReducer from "./user/userReducer";
import customerReducer from "./customers/customerReducer";

import countryReducer from "./countries/countryReducer";
import deliveryReducer from "./delivery/deliveryReducer";
import branchReducer from "./branch/branchReducer";

import warehouseReducer from "./warehouse/warehouseReducer";
import transportReducer from "./transport/transportReducer";

const rootReducer = combineReducers({
  branch: branchReducer,
  calendar: calendarReducer,
  contact: contactReducer,
  ecommerce: ecommerceReducer,
  customise: customiseReducer,
  vehicle: vehicleReducer,
  packageTypes: packageTypesReducer,
  productTypes: productTypesReducer,
  user: userReducer,
  auth: authReducer,
  customer: customerReducer,
  country: countryReducer,
  delivery: deliveryReducer,
  warehouse: warehouseReducer,
  transport: transportReducer,
});

export default rootReducer;
