import { lazy } from "react";

const SystemConfigRoutes = [
    {
        path: "/system-config/package-types",
        component: lazy(() => import("pages/system-config/package-types/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/system-config/product-types",
        component: lazy(() => import("pages/system-config/product-types/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/system-config/branches",
        component: lazy(() => import("pages/system-config/branches/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/system-config/accounts/customers",
        component: lazy(() => import("pages/system-config/accounts/customers/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/system-config/accounts/users",
        component: lazy(() => import("pages/system-config/accounts/users/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/system-config/warehouses",
        component: lazy(() => import("pages/system-config/warehouses/index")),
        layout: "VerticalLayout",
    },
];

export default SystemConfigRoutes;