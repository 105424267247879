export const FETCH_BRANCHES_SUCCESS = "FETCH_BRANCHES_SUCCESS";
export const FETCH_BRANCHES_ERROR = "FETCH_BRANCHES_ERROR";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_ERROR = "CREATE_BRANCH_ERROR";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_ERROR = "UPDATE_BRANCH_ERROR";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_ERROR = "DELETE_BRANCH_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_NEW_BRANCH = "SET_NEW_BRANCH";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_ERROR = "SET_ERROR";
export const SET_SELECTED_BRANCH = "SET_SELECTED_BRANCH";
export const TOGGLE_CREATE_EDIT_MODAL = "TOGGLE_CREATE_EDIT_MODAL";

